<template>
  <div class="g-section job-container">
    <div class="g-wrap job-top">
      <div class="flex">
        <!-- <span>筛选</span> -->
        <!-- <el-input placeholder="请输入关键字查询" suffix-icon="el-icon-search" v-model="keyword" size="small">
        </el-input>-->
      </div>
      <el-button type="primary" @click="goCreate" size="middle" icon="el-icon-circle-plus-outline">发布招生动态</el-button>
    </div>
    <div class="g-wrap job-bottom" v-loading="loading">
      <list text="暂未发布招生动态" :dataList="list" :totalCount="totalCount" :loading="loading" @loadMore="load">
        <div class="item flex p-16" v-for="(item, index) in list" :key="index">
          <div class="left">
            <div class="headline">{{ item.Headline }}</div>
            <div class="time mt-8">发布时间：{{ item.ReleaseTime }}</div>
          </div>

          <div class="right">
            <div class="entry" @click="goApply(item.Id)">
              <div class="flex">
                <div class="fs-16">
                  <span>{{ item.ApplyCount }}</span>
                  <span class="fs-12">人报名</span>
                </div>
                <span class="el-icon-arrow-right fs-12 color-gray ml-8 mt-4"></span>
              </div>
            </div>
            <!-- <div class="entry" @click="$router.push(`/enterprise/recruitnews/applylist?id=${item.Id}`)">
              <div class="flex">
                <div class="fs-16">
                  <span>{{ item.ApprovalCount }}</span>
                  <span class="fs-12">人通过</span>
                </div>
                <span class="el-icon-arrow-right fs-12 color-gray ml-8 mt-4"></span>
              </div>
            </div> -->
            <div class="ml-80">
              <!-- <el-button type="text" icon="el-icon-finished" size="small" @click="$router.push(`/enterprise/recruitnews/applylist?id=${item.Id}`)">0人已报名</el-button> -->
              <!-- <el-button v-if="showShare" type="text" icon="el-icon-share" size="small" @click="share(item.Id)">分享</el-button> -->
              <el-button type="text" icon="el-icon-edit" size="small" @click="goEdit(item.Id)">编辑
              </el-button>
              <el-button type="text" icon="el-icon-delete" size="small" @click="handleDelete(item.Id, index)">删除</el-button>
            </div>
          </div>
        </div>
      </list>
    </div>
    <el-dialog title="分享" :visible.sync="dialogVisible" width="680px">
      <div class="share-box">
        <div class="div-row">
          <span class="project-name">分享链接</span>
          <div class="project-box">
            <input id="myurl" class="input-myurl" v-model="downloadData.link" readonly type="text" />
            <button class="btn-copy" @click="handleCopy">复制</button>
          </div>
        </div>
        <div class="div-row">
          <span class="project-name">分享小程序码</span>
          <div class="project-box">
            <div class="div-qr" id="qrcode">
              <img :src="downloadData.url" class="qr-img" />
            </div>
            <div class="div-qr-remark">
              <span>使用手机微信“扫一扫”此小程序码进入小程序</span>
              <el-button @click="downloadImg" type="text" color="primary">下载小程序码</el-button>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getPageList, deleteEntity } from "@/api/recruitnews";
// import { getEnterpriseAndStaffByUserId } from "@/api/enterprise/enterpriseStaff";
import List from "@/components/Common/List";
import { dateTimeFilter } from "@/filters/index";
import { getShareQr } from "@/api/common.js";
import { ShareTypeEnum, StaffTypeEnum } from "@/utils/constant.js";
import { mapGetters } from 'vuex';
export default {
  components: { List },
  filters: { dateTimeFilter },
  data() {
    return {
      list: [],
      keyword: "",
      loading: false,
      pageIndex: 0,
      pageSize: 5,
      totalCount: 0,
      // enterpriseId: "",
      dialogVisible: false,
      downloadData: {
        url: "",
        link: ""
      },
      showShare: process.env.ENV !== "production"
    };
  },
  computed: {
    ...mapGetters(["employee"]),
    UnitType() {
      const { matched } = this.$route;
      if (matched.some(({ path }) => path.includes("enterprise"))) {
        return StaffTypeEnum.Enterprise;
      } else if (matched.some(({ path }) => path.includes("school"))) {
        return StaffTypeEnum.School;
      }
      return undefined;
    },
    enterpriseId() {
      const { Employer } = this.employee.find(({ StaffType }) => StaffType === StaffTypeEnum.Enterprise) || {};
      return Employer && Employer.Id;
    },
    routeBase() {
      //todo:暂时这样，后续需要仔细判断
      return this.UnitType === StaffTypeEnum.Enterprise ? "enterprise" : "school";
    },
  },
  created() {
    // this.enterpriseId = res.Result.Enterprise.Id;
    this.search();

  },
  methods: {
    load() {
      this.loading = true;
      this.pageIndex++;
      var queryData = {
        KeyWord: this.KeyWord,
        PageSize: this.pageSize,
        PageIndex: this.pageIndex,
        EnterpriseId: this.enterpriseId
      };
      getPageList(queryData).then(({ Result: { Data, TotalCount } }) => {
        this.list = this.list.concat(Data);
        this.totalCount = TotalCount;
        this.loading = false;
      });
    },
    search() {
      this.loading = true;
      this.pageIndex = 1;
      var queryData = {
        KeyWord: this.keyword,
        PageSize: this.pageSize,
        PageIndex: this.pageIndex,
        EnterpriseId: this.enterpriseId
      };
      console.log("search", queryData);
      getPageList(queryData).then(({ Result: { Data, TotalCount } }) => {
        this.list = Data;
        this.totalCount = TotalCount;
        this.loading = false;
      });
    },
    handleDelete(id, index) {
      this.$confirm("删除后无法恢复，确认删除？", "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          deleteEntity(id).then(_ => {
            this.list.splice(index, 1);
            if (this.totalCount > 0) {
              this.totalCount--;
            }
            this.$message({
              message: "删除成功",
              type: "success"
            });
          });
        })
        .catch(_ => { });
    },
    // 分享
    share(id) {
      const params = {
        Type: ShareTypeEnum.Both,
        Path: "pagesB/recruit/detail",
        Query: "id=" + id
      };
      getShareQr(params).then(res => {
        if (res.IsSuccess) {
          this.downloadData.url = res.Result.QrCode;
          this.downloadData.link = res.Result.UrlLink;
          this.dialogVisible = true;
        }
      });
    },
    // 复制
    handleCopy() {
      var url = document.getElementById("myurl");
      url.select(); // 选择对象
      document.execCommand("Copy");
      this.$message.success("已成功复制连接粘贴板！");
    },
    //下载图片 qrBase64是后台传回来的base64数据
    downloadImg() {
      var imgUrl = this.downloadData.url;
      // 如果浏览器支持msSaveOrOpenBlob方法（也就是使用IE浏览器的时候），那么调用该方法去下载图片
      if (window.navigator.msSaveOrOpenBlob) {
        const bstr = atob(imgUrl.split(",")[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        const blob = new Blob([u8arr]);
        window.navigator.msSaveOrOpenBlob(blob, "广东轻工职业技术学院" + "." + "png");
      } else {
        // 这里就按照chrome等新版浏览器来处理
        const a = document.createElement("a");
        a.href = imgUrl;
        a.setAttribute("download", "广东轻工职业技术学院");
        a.click();
      }
    },
    goCreate() {
      this.$router.push(`/${this.routeBase}/recruitnews/create`)
    },
    goDetail(id) {
    },
    goApply(id) {
      this.$router.push(`/${this.routeBase}/recruitnews/applylist?id=${id}`)
    },
    goEdit(id) {
      this.$router.push(`/${this.routeBase}/recruitnews/edit?id=${id}`)
    }
  }
};
</script>
<style lang="scss" scoped>
.job-container {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: #f5f5f5;
}
.job-top {
  justify-content: space-between;
  margin-top: 20px;
  border-radius: 8px 8px 0px 0px;
}
.job-bottom {
  display: flex;
  flex-direction: column;
  border-radius: 0px 0px 8px 8px;
}
.g-wrap {
  background: #fff;
}
.g-wrap.job-top {
  padding: 24px 24px 16px;
  border-bottom: 1px solid #eeeeee;
}
.g-wrap.job-bottom {
  padding: 16px 24px;
}
.item {
  border-bottom: 1px solid #eee;
  background: #ffffff;
  height: 88px;
  box-sizing: border-box;
  &:hover {
    box-shadow: 0px 4px 12px 0px rgba(51, 51, 51, 0.16),
      0px -1px 0px 0px #eeeeee;
  }
  .left {
    flex: 1;
    display: flex;
    flex-direction: column;
    .headline {
      font-size: 18px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
    }
    .time {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
    }
  }
  .right {
    display: flex;
    align-items: center;
    .entry {
      // display: flex;
      margin-left: 40px;
      color: #333;
      &:hover {
        color: #598DF3;
        cursor: pointer;
      }
    }
  }
}
.share-box {
  width: 100%;
  flex-direction: column;
  padding: 20px 30px;
  box-sizing: border-box;
}
.div-row {
  flex-direction: column;
  margin-bottom: 20px;
}
.project-name {
  font-size: 14px;
  font-family: 'MicrosoftYaHei';
}
.project-box {
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}
.btn-copy {
  width: 80px;
  height: 34px;
  border: 1px solid #598DF3;
  background-color: #598DF3;
  color: #ffffff;
  border-radius: 2px;
  margin-left: 10px;
  font-size: 14px;
  cursor: pointer;
  font-family: 'MicrosoftYaHei';
  outline: none;
  &:hover {
    opacity: 0.8;
  }
}
.btn-open {
  width: 80px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #4971f1;
  border-radius: 2px;
  color: #4971f1;
  text-align: center;
  line-height: 32px;
  margin-left: 10px;
  font-size: 14px;
  font-family: 'MicrosoftYaHei';
  &:hover {
    opacity: 0.8;
  }
}
.input-myurl {
  width: 460px;
  height: 34px;
  background: rgba(247, 248, 250, 1);
  border: 1px solid rgba(215, 217, 226, 1);
  padding: 0 5px;
  &:focus {
    border: 1px solid #409eff;
    outline: none;
  }
}
a {
  text-decoration: none;
}
.div-qr {
  width: 200px;
  height: 200px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(215, 217, 226, 1);
  padding: 3px;
}
.qr-img {
  display: block;
  width: 196px;
  height: 196px;
}
.div-qr-remark {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  align-items: flex-start;
}
.text-remark {
  color: #333333;
  font-size: 14px;
  font-family: 'MicrosoftYaHei';
}
</style>
